export async function client(endpoint, { body, ...customConfig } = {}, getType) {
  const headers = { 
    'Content-Type': 'application/json',
  }

  const env = window._jsenv || process.env
  const baseUrl = env.REACT_APP_API_BASE_URL;

  const config = {
    method: body ? 'POST' : 'GET',
    ...customConfig,
    headers: {
      ...headers,
      ...customConfig.headers,
    },
  }

  if (body) {
    config.body = JSON.stringify(body)
  }

  let data
  try {
    const response = await window.fetch(baseUrl + endpoint, config);
    if (response.ok) {
      return {
        status: response.status,
        data: getType === 'blob' ? response.blob(): response.json(),
        headers: response.headers,
        url: response.url,
      }
    }
    throw new Error(response.statusText)
  } catch (err) {
    return Promise.reject(err.message ? err.message : data)
  }
}

client.get = function (endpoint, customConfig = {}) {
  return client(endpoint, { ...customConfig, method: 'GET' })
}

client.getBlob = function (endpoint, customConfig) {
  return client(endpoint, { ...customConfig, method: 'GET' }, 'blob')
}

client.put = async function (endpoint, body, customConfig = {}) {
  const env = window._jsenv || process.env
  const baseUrl = env.REACT_APP_API_BASE_URL;

  try {
    const response = await window.fetch(baseUrl + endpoint, {
      method: 'PUT',
      body: body,
      headers: {
        ...customConfig.headers
      }
    });
    if (response.ok) {
      return Promise.resolve({});
    }
    throw new Error(await response.json().then(data => JSON.stringify(data.message)))
  } catch (err) {
    return Promise.reject(err.message ? err.message : body)
  }
}

client.post = function (endpoint, body, customConfig = {}) {
  return client(endpoint, { ...customConfig, body })
}

client.patch = async function (endpoint, body, customConfig = {}) {
  const env = window._jsenv || process.env
  const baseUrl = env.REACT_APP_API_BASE_URL;
  const headers = { 
    'Content-Type': 'application/json',
  }

  if (body) {
    body = JSON.stringify(body)
  }

  try {
    const response = await window.fetch(baseUrl + endpoint, {
      method: 'PATCH',
      body: body,
      headers: {
      ...headers,
      ...customConfig.headers,
    },
    });
    if (response.ok) {
      return Promise.resolve({});
    }
    throw new Error(response.statusText)
  } catch (err) {
    return Promise.reject(err.message ? err.message : body)
  }
}