import { configureStore, combineReducers } from '@reduxjs/toolkit';
import userReducer from './Slices/User';
import domainReducer from './Slices/Domain';

const combinedReducer = combineReducers({
  user: userReducer,
  domain: domainReducer
})

const rootReducer = (state, action) => {
  if (action.type === 'user/logout' || action.type === 'app/resetApp')
  {
    state = undefined;
  }

  return combinedReducer(state, action);
}

export default configureStore({
  reducer: rootReducer
});