import React from 'react'
import errorIcon from '../../../../assets/icons/errorImage.png'
import infoIcon from '../../../../assets/icons/infoSquare.png'
import './workEmail.css'

function WorkEmailModal(props) {
    const closeFormModal = props.closeModal
    const setShowCalendly = props.setShowCalendly
    const setFormCalendlyIsOpen =props.setFormCalendlyIsOpen

    const showPopUp = ()=>{
        closeFormModal()
        setShowCalendly(true)
        setFormCalendlyIsOpen(true)
     }

  return (
    <div style={{height:"100%",width:"100%",display:"flex",justifyContent:"space-around",flexDirection:"column",padding:"10px",boxSizing:"border-box",alignItems:'center'}}>
        <span>
            <img src={infoIcon}/>
        </span>
        <p style={{fontWeight:'700',fontSize:"30px"}}>
            Prohibited Email Address
        </p>

        <p style={{margin:'10px 0px',textAlign:"center"}}>
            Personal email addresses are not allowed. Please use a work email address (eg. name@workmail.com).
        </p>

        <section>
            For assistance <span onClick={()=>showPopUp()} className='spans'>book a demo</span>, if you have an office mail <span onClick={()=>closeFormModal()} className='spans'>continue sign up</span>.
        </section>
    </div>
  )
}

export default WorkEmailModal