import React, { useState } from "react";
import "./Home.css";
import "react-toastify/dist/ReactToastify.css";
import { RegistrationViews } from "../../library/Variables";
import Register from "./Sections/Register";
import EmailVerification from "./Sections/EmailVerification";

function Home() {
  let content = null;

  const [view, setView] = useState(RegistrationViews.FORM);
  const [formFields, setFormFields] = useState({ domain: null, email: null });

  const handleFormCallback = (domain, email) => {
    setFormFields({ domain: domain, email: email });
    setView(RegistrationViews.VERIFY);
  }

  if(view === RegistrationViews.FORM) {
    content = <Register parentCallback={(domain, email) => handleFormCallback(domain, email)} />
  } else if(view === RegistrationViews.VERIFY) {
    content = <EmailVerification fields={formFields} />
  }

  return(
    <div className="home-body">
      <div className="home-main">
        <div className="registration-left" style={{ backgroundImage: `url(${"/assets/images/background.png"})` }}>
          <div className="home-left-container">
            <img src="/assets/logo/logo-white.svg" alt="" />
            <img src="/assets/images/bg-image.svg" alt="" />
          </div>
        </div>     
        <div className="home-right">
          <div className="home-right-container">
            {content}
          </div>      
        </div>
      </div>
    </div>
  )
}

export default Home;