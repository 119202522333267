import React from "react";
import { useFormContext } from "react-hook-form";

function Select(props) {
  const { register, formState: { errors } } = useFormContext();
  const fields = props.fields;

  return <div className="form-input-container">
    {fields.label && <label className="input-labels">{fields.label}</label>}
    <select className={errors[fields.register] ? "registration-inputs error-input" : "registration-inputs"}
      {...register(fields.register, fields.validators)}>
      <option value="" hidden>{fields.placeholder}</option>
      {fields.options.map((option, i) => { return <option key={i} value={option.value}>{option.value}</option>})}
    </select>
    <span className="error-msg">{errors[fields.register]?.message}</span>
  </div>
}

export default Select;