import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import Input from "../../../components/FormFields/Input";

function EmailVerificationForm(props) {
  const fields = props.fields;
  const methods = useForm({
    mode: "onBlur"
  });

  const onSubmit = (formData) => {
    props.parentCallback(formData.otp);
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} className="verify-form-container">
        <h2 className="text-header">Enter OTP</h2>
        <p>Please enter OTP sent to <span>{fields.email}</span></p>
        <Input fields={{ register: "otp", placeholder: "", type: "text", validators: { required: "OTP code is required" } }} />
        <div className="buttons-container">
          <button type="submit" className="btn-registration">Verify</button>
        </div>
        <p className="link-paragraph">Not received OTP? <span className="text-header-medium" 
          onClick={() => props.parentCallbackResend()}>Resend</span></p>
      </form>
    </FormProvider>
  )
}

export default EmailVerificationForm;