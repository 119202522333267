import "./App.css";
import Home from "../Home/Home";
import { Routes, Route } from "react-router-dom";
import NotFound from "../NotFound/NotFound";
import "react-toastify/dist/ReactToastify.css";

function App() {   
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="*" element={<NotFound />} />
      <Route path="/404" element={<NotFound />} />
    </Routes>
  );
}

export default App;