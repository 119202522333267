import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { PostDataStatus } from "../../library/Variables";
import { client } from "../../api/Httpclient";

export const registerUserAsync = createAsyncThunk('registeruser',  async (params) => {
  const response = await client.post('/onboard/register', params.data);

  return response.data;
});

export const verifyUserOtp = createAsyncThunk('verifyUserOtp', async (params) => {
  let data = {
    email: params.email,
    otp: params.otp
  }

  const response = await client.post('/onboard/verify', data);

   return response.data;
});

export const startOnboardProcess = createAsyncThunk('onboardUser', async (params) => {
  let data = {
    uuid: params.tenantUuid
  }

  const response = await client.post('/onboard/process', data);

  return response.data;
})

export const sendUserOtp = createAsyncThunk('sendUserOtp', async (params) => {
  const response = await client.post(`/onboard/send/${params.email}`)

  return response.data;
});

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    registerUserStatus: PostDataStatus.INITIAL,
    verifyUserOtpResponse: {},
    verifyUserOtpStatus: PostDataStatus.INITIAL,
    onboardingStatus: PostDataStatus.INITIAL,
    sendOtpStatus: PostDataStatus.INITIAL,
    isError: false,
    errorMessage: null
  },
  reducers: {
    resetUser: state => {
      state.registerUserStatus = PostDataStatus.INITIAL;
      state.verifyUserOtpResponse = {};
      state.verifyUserOtpStatus = PostDataStatus.INITIAL;
      state.onboardingStatus = PostDataStatus.INITIAL;
      state.sendOtpStatus = PostDataStatus.INITIAL;
      state.isError = false;
      state.errorMessage = null;
    },
    resetVerfiyUserOtp: state => {
      state.verifyUserOtpResponse = {};
      state.verifyUserOtpStatus = PostDataStatus.INITIAL;
      state.onboardingStatus = PostDataStatus.INITIAL;
      state.sendOtpStatus = PostDataStatus.INITIAL;
      state.isError = false;
      state.errorMessage = null;
    },
    resetResendEmail: state => {
      state.sendOtpStatus = PostDataStatus.INITIAL;
      state.isError = false;
      state.errorMessage = null;
    }
  },
  extraReducers(builder) {
    builder.addCase(registerUserAsync.pending, (state) => {
      state.registerUserStatus = PostDataStatus.FETCHING;
    })
    .addCase(registerUserAsync.fulfilled, (state) => {      
      state.registerUserStatus = PostDataStatus.SUCCESS;
    })
    .addCase(registerUserAsync.rejected, (state, action) => {
      state.registerUserStatus = PostDataStatus.FAILURE;
      state.isError = true;
      state.errorMessage = action.error.message;
    })
    .addCase(verifyUserOtp.pending, (state) => {
      state.verifyUserOtpStatus = PostDataStatus.FETCHING;
    })
    .addCase(verifyUserOtp.fulfilled, (state, action) => {
      state.verifyUserOtpStatus = PostDataStatus.SUCCESS;
      state.verifyUserOtpResponse = action.payload;
    })
    .addCase(verifyUserOtp.rejected, (state, action) => {
      state.verifyUserOtpStatus = PostDataStatus.FAILURE;
      state.errorMessage = action.error.message;
    })
    .addCase(startOnboardProcess.pending, (state) => {
      state.onboardingStatus = PostDataStatus.FETCHING;
    })
    .addCase(startOnboardProcess.fulfilled, (state) => {      
      state.onboardingStatus = PostDataStatus.SUCCESS;
    })
    .addCase(startOnboardProcess.rejected, (state, action) => {
      state.onboardingStatus = PostDataStatus.FAILURE;
      state.errorMessage = action.error.message;
    })
    .addCase(sendUserOtp.pending, (state) => {
      state.sendOtpStatus = PostDataStatus.FETCHING;
    })
    .addCase(sendUserOtp.fulfilled, (state) => {      
      state.sendOtpStatus = PostDataStatus.SUCCESS;
    })
    .addCase(sendUserOtp.rejected, (state, action) => {
      state.sendOtpStatus = PostDataStatus.FAILURE;
      state.errorMessage = action.error.message;
    })
  }
});

export const { resetUser, resetVerfiyUserOtp, resetResendEmail, user } = userSlice.actions;

export const selectUserState = state => state.user;

export default userSlice.reducer;