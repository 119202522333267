import React, { useState } from "react";
import { useFormContext } from "react-hook-form";
import { Countries } from "../../library/Variables";

function Phone() {
  const [countryIndex, setCountryIndex] = useState(0);
  const { register, formState: { errors } } = useFormContext();

  return <div className="form-input-container">
    <div className="form-row phone-row">
      <div className="form-input-container">
        <label className="input-labels">Country</label>
        <div className="input-container">
          <img src={Countries[countryIndex].image} className="registration-icon input-icon" alt="" />
          <select className={errors.countryCode ? "registration-inputs error-input" : "registration-inputs"}
              {...register("countryCode", { 
                required: "Please select a country",
                onChange: (e) => setCountryIndex(e.target.value)
              })}>
              {Countries.map((element, i) => {
                return <option value={i} key={i}>{element.name}</option>
              })}
          </select>
        </div>
      </div>
      <div className="form-input-container">
        <label className="input-labels">Phone Number</label>
        <div className="input-container mobile-phone-wrapper">
          <div className="mobile-phone-code">
            {Countries[countryIndex].code}
          </div>
          <input type="text" className={errors.phone ? "registration-inputs error-input" : "registration-inputs"}
            {...register("phone", { 
              required: "Please enter a valid phone number",
              pattern: {
                value: Countries[countryIndex].regex,
                message: "Invalid phone number"
              }, })} />
        </div>

      </div>
    </div>
    <span className="error-msg">{errors.phone?.message}</span>
  </div>
}

export default Phone;