import React from "react";
import { useFormContext } from "react-hook-form";

function Accept(props) {
  const { register, formState: { errors } } = useFormContext();

  return <div className="form-input-container">
    <label className="registration-accept-container">
      <input type="checkbox" id="accept-terms" {...register("acceptTerms", 
        { required: "Please agree to our privacy policy to proceed" })} />
      <span className="checkmark"></span>
      <span className="registration-accept-text">
        I agree to Periculum's&nbsp;
        <a href="https://www.periculum.io/terms-of-use" target={"_blank"} rel={"noreferrer"}>Terms of Use</a> 
        &nbsp;and <a href="https://www.periculum.io/privacy-policy" target={"_blank"} rel={"noreferrer"}>Privacy Policy</a>
      </span>
    </label>
    <span className="error-msg">{errors.acceptTerms?.message}</span>
  </div>
}

export default Accept;