import React, { useEffect, useState } from "react";
import "./Register.css";
import { useDispatch, useSelector } from "react-redux";
import { Countries, PostDataStatus } from "../../../library/Variables";
import RegisterForm from "./RegisterForm";
import { registerUserAsync, resetUser, selectUserState } from "../../../redux/Slices/User";
import Spinner from "../../../components/Spinner/Spinner";

function Register(props) {
  let content = null;

  const dispatch = useDispatch();
  const user = useSelector(selectUserState);
  const [formFields, setFormFields] = useState({ domain: null, email: null });

  const handleFormCallback = (formData) => {
    let params = {
      data: formData
    };
    params.data.region = Countries[formData.countryCode].name;

    setFormFields({ domain: formData.domainName, email: formData.email });
    dispatch(registerUserAsync(params));
  }

  useEffect(() => {
    if(user.registerUserStatus === PostDataStatus.SUCCESS) {
      dispatch(resetUser());
      props.parentCallback(formFields.domain, formFields.email);
    }
  }, [user.registerUserStatus])

  if(user.registerUserStatus === PostDataStatus.INITIAL) {
    content = <>
      <h2 className="text-darker title">Get started</h2>
      <p className="text-medium-dark subtitle">Let's get you set up on Insights</p>
      <RegisterForm parentCallback={(formData) => handleFormCallback(formData)} />
    </>
  } else if(user.registerUserStatus === PostDataStatus.FETCHING) {
    content = <>
      <h2 className="text-darker title">Sending OTP</h2>
      <p className="text-medium-dark subtitle">Please wait while we send your OTP</p>
      <Spinner />
    </>
  } else if(user.registerUserStatus === PostDataStatus.FAILURE) {
    content = <>
      <h2 className="text-darker">Registration Failed</h2>
      <p className="text-medium-dark">Unable to process registration</p>
      <p className="text-medium-dark">{user.errorMessage}</p>
      <button className="registration-button" onClick={() => dispatch(resetUser())}>Try again</button>
    </>
  }

  return content
}

export default Register;