import React, { useEffect, useState } from "react";
import "./Domain.css";
import { useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { checkDomainNameUsabilityAsync, resetDomainChecker, selectDomainState } from "../../redux/Slices/Domain";
import { FetchDataStatus } from "../../library/Variables";
import Spinner from "../Spinner/Spinner";

function Domain(props) {
  const dispatch = useDispatch();
  const domain = useSelector(selectDomainState);
  const { register, watch, setValue, formState: { errors } } = useFormContext({

  });
  const watchDomain = watch("domainName", null);
  const [isInputDisabled, setIsInputDisabled] = useState(false);

  const checkDomainName = () => {
    if(!errors.domainName) {
      dispatch(checkDomainNameUsabilityAsync({
        domain: watchDomain
      }));
    }
  }

  const checkFormState = () => {
    if(domain.domainCheckStatus === FetchDataStatus.SUCCESS) {
      dispatch(resetDomainChecker());
      props.parentCallback(false);
    }
  }

  useEffect(() => {
    if(domain.domainCheckStatus === FetchDataStatus.SUCCESS) {
      setIsInputDisabled(false);
      props.parentCallback(true);
    } else if(domain.domainCheckStatus === FetchDataStatus.FETCHING) {
      setIsInputDisabled(true);
    } else {
      setIsInputDisabled(false);
    }
  }, [domain.domainCheckStatus])

  useEffect(() => {
    if(props.firstWord) {
      setValue('domainName', props?.firstWord);
      checkFormState(props?.firstWord)
      checkDomainName()
    }

  }, [props.firstWord])

  return (
    <div className="form-input-container registration-domain-container">
      <label className="input-labels">Custom Domain</label>
      <div className="form-domain-row-container">
        <div className="form-domain-input-container">
          <span>https://</span>
          <input className={errors.domainName ? "error-input" : ""} type="text" disabled={isInputDisabled} defaultValue={props.firstWord}
            {...register("domainName", { 
              required: "Domain name cannot be empty",
              minLength: {
                value: 3,
                message: "Domain must be at least 3 characters"
              },
              maxLength: {
                value: 20,
                message: "Domain name cannot exceed 20 characters"
              },
              pattern: {
                value: /^[a-zA-Z0-9-]*$/,
                message: "Domain can only contain alpha numeric or a dash"
              },
              onChange: (e) => {checkFormState(e.target.value); setValue(e.target.value)},
              onBlur: () => checkDomainName()
            })} placeholder="domain-name" />
          <span>.insights-periculum.com</span>
        </div>
        {domain.domainCheckStatus === FetchDataStatus.INITIAL
          ? null
          : domain.domainCheckStatus === FetchDataStatus.FETCHING
            ? <div className="form-domain-status domain-status-fetching">
                <Spinner />
                <span>Checking</span>
              </div>
            : domain.domainCheckStatus === FetchDataStatus.FAILURE
              ? <>
                  <div className="form-domain-status domain-status-fail">
                    <img src="/assets/icons/close-circle.svg" alt="" />
                    <span>Name not available</span>
                  </div>
                </>
              : domain.domainCheckStatus === FetchDataStatus.SUCCESS
                ? <div className="form-domain-status domain-status-success">
                    <img src="/assets/icons/tick-circle-green.svg" alt="" />
                    <span>Name available</span>
                  </div>
                : null
        }
      </div>
      <span className="error-msg">{errors.domainName?.message}</span>
    </div>
  )
}

export default Domain;