import React from "react";
import { useFormContext } from "react-hook-form";

function Input(props) {
  const { register, formState: { errors } } = useFormContext();
  const fields = props.fields;

  return <div className="form-input-container">
    {fields.label && <label className="input-labels">{fields.label}</label>}
    <input type={fields.type} {...register(fields.register, fields.validators)} autocomplete="off" placeholder={fields.placeholder}
      className={errors[fields.register] ? "registration-inputs error-input" : "registration-inputs"} />
    <span className="error-msg">{errors[fields.register]?.message}</span>
  </div>
}

export default Input;