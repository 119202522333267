import React, { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import Utils from "../../../library/Utils";
import Input from "../../../components/FormFields/Input";
import Domain from "../../../components/FormFields/Domain";
import Select from "../../../components/FormFields/Select";
import Phone from "../../../components/FormFields/Phone";
import Accept from "../../../components/FormFields/Accept";
import { toast } from "react-toastify";
import InputEmail from "../../../components/FormFields/InputEmail";
import Modal from "react-modal";
import WorkEmailModal from "./Modal Section/WorkEmailModal";
import CalendlyPop from "./Calendly/PopUpWidget";

function RegisterForm(props) {
  const methods = useForm({
    mode: "onBlur"
  });
  const { setValue } = methods;
  
  const watchFields = methods.watch(["password", "business"]);
  const [isDomainValid, setIsDomainValid] = useState(false);
  const [modalFormIsOpen, setFormIsOpen] = useState(false);
  const [modalFormCalendlyIsOpen, setFormCalendlyIsOpen] = useState(false);
  const [showCalendly,setShowCalendly] = useState(false)
  const firstWord = watchFields[1]?.split(' ')[0];

  const onSubmit = (formData) => {
    if(isDomainValid) {
      props.parentCallback(formData);
      methods.reset();
    } else {
      toast.info("Please check domain availability before submitting the form", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  }

  function afterOpenModal() { }

  function closeFormModal() {

    setFormIsOpen(false)
    //dispatch(resetDownload());
  }

  function closeFormCalendlyModal() {

    setFormCalendlyIsOpen(false)
    //dispatch(resetDownload());
  }


  const customStyles = {
    content: {
      transition: '0.125s ease-in-out',
      transform: 'scale(1)',
      height: '33%',
      width: '50%',
      top: '33%',
      left: '33%',
      padding: '0 0 0 0',
      overflowY: 'hidden',
      backgroundColor:"white",
      borderRadius:"15px"
    },
    overlay: {
      zIndex: 1000,
      position: 'absolute'
    }
  };

  const customStylesCalendly = {
    content: {
      transition: '0.125s ease-in-out',
      
      height: 'auto',
      width: '50%',
      top: '7.5vh',
      left: '25vw',
      padding: '0px',
      backgroundColor:"white",
      borderRadius:"15px",
      overflowY:'hidden'
    },
    overlay: {
      zIndex: 1000,
      position: 'fixed',
      display:"flex",
      alignItems:"center",
      justifyContent:"center"
    }
  };


  //  /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
  return (
    <FormProvider {...methods}>
      <form className="registration-form-container" onSubmit={methods.handleSubmit(onSubmit)}>
        {/* First Name and Last Name */}
        <div className="form-row">
          <Input fields={{ label: "First Name", register: "firstName", placeholder: "Enter your first name", type: "text", validators: { 
            required: "First name is required" } }} />
          <Input fields={{ label: "Last Name", register: "lastName", placeholder: "Enter your last name", type: "text", validators: { 
            required: "Last name is required" } }} />
        </div>
        {/* Work Email */}
        <InputEmail fields={{ label: "Work email", register: "email", placeholder: "name@company.com", type: "text", validators: { 
          required: "Email is required",
          pattern: { value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,7}$/, message: "Invalid email" }} }} 
          set={setValue}
          setFormIsOpen={setFormIsOpen}
          
          />
        {/* Phone Number */}
        <Phone />
        {/* Password */}
        <div className="form-row">
          <Input fields={{ label: "Password", register: "password", placeholder: "****************", type: "password", validators: { 
            required: "Password is required", minLength: { value: 8, message: "Password must be at least 8 characters" }} }} />
          <Input fields={{ label: "Confirm Password", register: "cPassword", placeholder: "****************", type: "password", validators: { 
            required: "Please confirm your password", minLength: { value: 8, message: "Password must be at least 8 characters" },
            validate: value => value === watchFields[0] || "The passwords do not match" 
          } }} />
        </div>
        {/* Company Name and Role */}
        <div className="form-row">
          <Input fields={{ label: "Company/Business name", register: "business", placeholder: "Enter your company's name", type: "text", validators: { 
            required: "Company/Business name is required"
          } }} />
          <Select fields={{ label: "Role", register: "role", placeholder: "Select your role in the company",
            validators: { required: "Please select a role"}, options: [
              { value: "Developer" },
              { value: "Product/Project Manager" },
              { value: "Credit/Risk Analyst" },
              { value: "Founder" },
              { value: "Others" }
            ] }} />
        </div>
        {/* Custom Domain */}
        {(firstWord?.length >= 3) && <Domain parentCallback={(isValid) => setIsDomainValid(isValid)} firstWord={firstWord} />}
        {/* RC Number and Company Type */}
        <div className="form-row">
          <Input fields={{ label: "Company Registration Number", register: "rcNumber", placeholder: "Enter your Company Registration Number", type: "text", validators: { 
            required: "Company Registration Number is required" } }} />
          <Select fields={{ label: "Company Type", register: "companyType", placeholder: "Select company type",
            validators: { required: "Company Type is required"}, options: [
              { value: "Digital lender" },
              { value: "Commercial and Microfinance banks" },
              { value: "BNPL" },
              { value: "Personal Finance" },
              { value: "Others" }
            ] }} />
        </div>
        {/* Number of Customers and Business Stage */}
        <div className="form-row">
          <Select fields={{ label: "Number of customers", register: "numberOfCustomers", placeholder: "Select range",
            validators: { required: "Number of customers is required"}, options: [
              { value: "Below 100" },
              { value: "100 - 500" },
              { value: "500 - 1000" },
              { value: "Above 1000" }
            ] }} />
          <Select fields={{ label: "Business stage", register: "businessStage", placeholder: "Select stage",
            validators: { required: "Business stage is required"}, options: [
              { value: "Planning" },
              { value: "Beta" },
              { value: "Development" },
              { value: "Production" }
            ] }} />
        </div>
        {/* Accept Checkbox */}
        <Accept />
        <div className="buttons-container">
          <button type="submit" className="btn-registration">Create Account</button>
        </div>
      </form>
      <Modal isOpen={modalFormIsOpen} onAfterOpen={afterOpenModal} onRequestClose={closeFormModal} style={customStyles}
          contentLabel="Form Upload" >
          <WorkEmailModal closeModal={closeFormModal} setShowCalendly={setShowCalendly} setFormCalendlyIsOpen={setFormCalendlyIsOpen}/>
      </Modal> 
      {showCalendly && <Modal isOpen={modalFormCalendlyIsOpen} onAfterOpen={afterOpenModal} onRequestClose={closeFormCalendlyModal} style={customStylesCalendly}
          contentLabel="Form Upload" >
          <CalendlyPop/>
      </Modal> }
    </FormProvider>
    
  )
}

export default RegisterForm;
