import React,{useState,useEffect} from "react";
import { useFormContext } from "react-hook-form";

function InputEmail(props) {
  const { register, formState: { errors } } = useFormContext();
  const fields = props.fields;
  const setValue = props.set
  const setFormIsOpen = props.setFormIsOpen
  const [changedEmail,setChangedEmail] = useState(' ')

  useEffect(()=>{
    const clearText = ()=>{ setChangedEmail('')}
    const timeoutId = setTimeout(clearText, 1500);

    // Clear the timeout when the component is unmounted or before the next update
    return () => clearTimeout(timeoutId);
  },[])

  const mailCheckers = ['yahoo.','gmail.','outlook.','protonmail.'
                        ,'zoho.','aol.','fastmail.','gmx.','yandex.',
                        'tutanota.','icloud.']
 const handleEmailChange = (e,title)=>{
    setChangedEmail(e.target.value)
    let data = e.target.value.split('@')

    if(e.target.value.includes('@')){
      for(let i =0; i<mailCheckers.length;i++){

        if(data[1].includes(mailCheckers[i])){
            setChangedEmail('')
            setValue(title,'')
            setFormIsOpen(true)
        }
        else{
            setValue(title,e.target.value)
        }
      }
    }

    else{
      setValue(title,e.target.value)
    }
   
 }

 

  return <div className="form-input-container">
    {fields.label && <label className="input-labels">{fields.label}</label>}
    <input type={fields.type} {...register(fields.register, fields.validators)} autocomplete="off" placeholder={fields.placeholder}
      className={errors[fields.register] ? "registration-inputs error-input" : "registration-inputs"} 
      value={changedEmail}
      onChange={(e) => handleEmailChange(e,fields.register)}
      />
    <span className="error-msg">{errors[fields.register]?.message}</span>
  </div>
}

export default InputEmail;