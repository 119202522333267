import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { FetchDataStatus } from "../../library/Variables";
import { client } from "../../api/Httpclient";

export const checkDomainNameUsabilityAsync = createAsyncThunk('checkdomain', async (params) => {
  const response = await client.get('/onboard/validate-domain/' + params.domain);
  
  return response.data;
})

export const domainSlice = createSlice({
  name: 'domain',
  initialState: {
    domain: {},
    domainCheckStatus: FetchDataStatus.INITIAL,
    isError: false,
    errorMessage: null
  },
  reducers: {
    resetDomainChecker: state => {
      state.domain = {};
      state.domainCheckStatus = FetchDataStatus.INITIAL;
      state.isError = false;
      state.errorMessage = null;
    }
  },
  extraReducers(builder) {
    builder.addCase(checkDomainNameUsabilityAsync.pending, (state) => {
      state.domainCheckStatus = FetchDataStatus.FETCHING;
    })
    .addCase(checkDomainNameUsabilityAsync.fulfilled, (state, action) => {      
      state.domainCheckStatus = FetchDataStatus.SUCCESS;
      state.domain = action.payload;
    })
    .addCase(checkDomainNameUsabilityAsync.rejected, (state, action) => {
      state.domainCheckStatus = FetchDataStatus.FAILURE;
      state.isError = true;
      state.errorMessage = action.error.message;
    })
  }
});

export const { resetDomainChecker, domain } = domainSlice.actions;

export const selectDomainState = state => state.domain;

export default domainSlice.reducer;