import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PostDataStatus } from "../../../library/Variables";
import { resetResendEmail, resetVerfiyUserOtp, selectUserState, sendUserOtp, startOnboardProcess, verifyUserOtp } from "../../../redux/Slices/User";
import EmailVerificationForm from "./EmailVerificationForm";
import "./EmailVerification.css";
import Spinner from "../../../components/Spinner/Spinner";
import Utils from "../../../library/Utils";
import { toast } from "react-toastify";

function EmailVerification(props) {
  let content = null;

  const dispatch = useDispatch();
  const user = useSelector(selectUserState);
  const fields = props.fields;

  const handleFormCallback = (otp) => {
    dispatch(verifyUserOtp({
      email: fields.email,
      otp: otp
    }));
  }
  
  const resendOtp = () => {
    dispatch(sendUserOtp({ email: fields.email }));
  }

  useEffect(() => {
    if(user.verifyUserOtpStatus === PostDataStatus.SUCCESS && !Utils.isFieldEmpty(user.verifyUserOtpResponse)) {
      dispatch(startOnboardProcess({ tenantUuid: user.verifyUserOtpResponse.data.tenantUuid }));
    }
  }, [user.verifyUserOtpStatus, dispatch])

  useEffect(() => {
    if(user.sendOtpStatus === PostDataStatus.SUCCESS) {
      toast.success("OTP code was sent successfully", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });

      dispatch(resetResendEmail());
    }
  }, [user.sendOtpStatus, dispatch])

  if(user.verifyUserOtpStatus === PostDataStatus.INITIAL) {
    content = <EmailVerificationForm fields={fields} parentCallback={(otp) => handleFormCallback(otp)}
      parentCallbackResend={() => resendOtp()} />
  } else if(user.verifyUserOtpStatus === PostDataStatus.FAILURE || user.onboardingStatus === PostDataStatus.FAILURE) {
    content = <>
      <h2 className="text-darker">Registration Failed</h2>
      <p className="text-medium-dark">Unable to verify your account</p>
      <button className="registration-button" onClick={() => dispatch(resetVerfiyUserOtp())}>Try again</button>
    </>
  } else {
    content = <>
      <h2 className="text-darker title">Verifying</h2>
      <p className="text-medium-dark subtitle">Please wait while we set up your account</p>
      <Spinner />
    </>

    if(user.onboardingStatus === PostDataStatus.SUCCESS) {
      content = <>
        <h2 className="text-header title">Congratulations!</h2>
        <p className="text-medium-dark subtitle">Your email has been verified</p>
        <p style={{ margin: "2em 0 0 0" }}>Please check your email for the welcome mail with your custom domain</p>
      </>
    }
  }

  return content
}

export default EmailVerification;