export const FetchDataStatus = {
  INITIAL: 'INITIAL',
  FETCHING: 'FETCHING',
  SUCCESS: 'SUCCESS',
  FAILURE: 'FAILURE'
}

export const PostDataStatus = {
  INITIAL: 'INITIAL',
  FETCHING: 'POSTING',
  SUCCESS: 'SUCCESS',
  FAILURE: 'FAILURE'
}

export const RegistrationViews = {
  FORM: "FORM",
  VERIFY: "VERIFY"
}

export const Countries = [
  {
    name: "NIGERIA",
    image: "/assets/images/nigeria-flag.png",
    code: "+234",
    regex: /^[0-9]{10}$/,
    region: "NGA"
  },
  {
    name: "KENYA",
    image: "/assets/images/kenya-flag.png",
    code: "+254",
    regex: /^[0-9]{9,10}$/,
    region: "KEN"
  },
  // {
  //   name: "GHANA",
  //   image: "/assets/images/ghana-flag.png",
  //   code: "+233",
  //   regex: /^[0-9]{9}$/,
  //   region: "GHA"
  // },
  // {
  //   name: "UGANDA",
  //   image: "/assets/images/uganda-flag.png",
  //   code: "+256",
  //   regex: /^[0-9]{9,10}$/,
  //   region: "UGA"
  // },
  // {
  //   name: "CANADA",
  //   image: "/assets/images/canada-flag.png",
  //   code: "+1",
  //   regex: /^[0-9]{10}$/,
  //   region: "CA"
  // }
]

export const TempParams = {
  GRANT_TYPE: "client_credentials"
}